import { IS_DEV } from './constants';

export function toArray(val) {
  return Array.isArray(val) ? val : [val];
}

export const tryCatchWrapper =
  (executable, res) =>
  async (...args) => {
    try {
      const result = await executable(...args);
      return result;
    } catch (e) {
      IS_DEV && console.log('ERROR', e);
      if (typeof e === 'string') {
        res.status(500).json({ message: e });
      } else {
        res.status(e.statusCode || 500).json({ message: JSON.stringify(e) });
      }
    }
  };

export const tryCatchWrapperFunction = async (func, req, res) => {
  const wrapperFunction = tryCatchWrapper(func, res);
  return await wrapperFunction(req, res);
};

export function tabA11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
